import { SUSPENSE } from '@react-rxjs/core'
import { Observable, of } from 'rxjs'

import {
  SearchExactParentOrChildSkuDocument,
  SearchExactParentOrChildSkuQuery,
} from '../../graphql/search'
import { mapRightSuspended } from '../../utils/rx/operators'
import { searchQuery, SearchQueryResult } from './query'

export type SearchExactProduct = SearchExactParentOrChildSkuQuery['exact']['results'][number]

const exactParentOrChildSkuQuery = searchQuery(SearchExactParentOrChildSkuDocument)

const mapProductsBySku = (products: SearchExactProduct[]): Map<string, SearchExactProduct> =>
  products.reduce((acc, product) => {
    acc.set(product.sku, product)
    return acc
  }, new Map<string, SearchExactProduct>())

export const searchExactQuery = ({
  skus,
}: {
  skus: string[]
}): Observable<SearchQueryResult<SearchExactProduct[]> | typeof SUSPENSE> =>
  !skus || skus.length === 0
    ? of({ _tag: 'Right' as const, data: [] })
    : exactParentOrChildSkuQuery({
        // sort skus for efficient caching
        variables: { sku: skus.slice().sort() },
      }).pipe(
        mapRightSuspended(({ data }) => ({
          _tag: 'Right' as const,
          // convert list to map for efficient product retrieval (by sku)
          data: mapProductsBySku(data?.exact?.results ?? []),
        })),
        mapRightSuspended(({ data: products }) => ({
          _tag: 'Right' as const,
          data: skus
            .map(Map.prototype.get.bind(products))
            .filter((product): product is SearchExactProduct => !!product),
        })),
      )
